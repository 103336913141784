export const ALL_TIMESHEET_REQUEST = 'ALL_TIMESHEET_REQUEST'
export const ALL_TIMESHEET_SUCCESS = 'ALL_TIMESHEET_SUCCESS'
export const ALL_TIMESHEET_FAIL = 'ALL_TIMESHEET_FAIL'

export const CLEAR_ERROR = 'CLEAR_ERROR'
export const MESSAGE_RESET = 'MESSAGE_RESET'
export const GET_SELECTED_TIMESHEET = 'GET_SELECTED_TIMESHEET'
export const UPDATE_SELECTED_TIMESHEET_WORKING_HOURS = 'UPDATE_SELECTED_TIMESHEET_WORKING_HOURS'

export const UPDATE_TIMESHEET_REQUEST = 'UPDATE_TIMESHEET_REQUEST'
export const UPDATE_TIMESHEET_SUCCESS = 'UPDATE_TIMESHEET_SUCCESS'
export const UPDATE_TIMESHEET_SUCCESS_NEW = 'UPDATE_TIMESHEET_SUCCESS_NEW'
export const UPDATE_TIMESHEET_FAIL = 'UPDATE_TIMESHEET_FAIL'

export const CREATE_TIMESHEET_REQUEST = 'CREATE_TIMESHEET_REQUEST'
export const CREATE_TIMESHEET_SUCCESS = 'CREATE_TIMESHEET_SUCCESS'
export const CREATE_TIMESHEET_FAIL = 'CREATE_TIMESHEET_FAIL'
export const CREATE_TIMESHEET_SUCCESS_NEW = 'CREATE_TIMESHEET_SUCCESS_NEW'

export const USER_TIMESHEET_REQUEST = 'USER_TIMESHEET_REQUEST'
export const USER_TIMESHEET_SUCCESS = 'USER_TIMESHEET_SUCCESS'
export const USER_TIMESHEET_FAIL = 'USER_TIMESHEET_FAIL'
export const USER_TIMESHEET_RESET = 'USER_TIMESHEET_RESET'
export const GET_SELECTED_TIMESHEET_BY_USER_ID = 'GET_SELECTED_TIMESHEET_BY_USER_ID'
export const UPDATE_SELECTED_TIMESHEET_WORKING_HOURS_BY_ID = 'UPDATE_SELECTED_TIMESHEET_WORKING_HOURS_BY_ID'

export const UPDATE_ISSUBMITTED_REQUEST = 'UPDATE_ISSUBMITTED_REQUEST'
export const UPDATE_ISSUBMITTED_SUCCESS = 'UPDATE_ISSUBMITTED_SUCCESS'
export const UPDATE_ISSUBMITTED_SUCCESS_NEW = 'UPDATE_ISSUBMITTED_SUCCESS_NEW'
export const UPDATE_ISSUBMITTED_FAIL = 'UPDATE_ISSUBMITTED_FAIL'

export const ALL_TIMESHEET_BY_MONTH_REQUEST = 'ALL_TIMESHEET_BY_MONTH_REQUEST'
export const ALL_TIMESHEET_BY_MONTH_SUCCESS = 'ALL_TIMESHEET_BY_MONTH_SUCCESS'
export const ALL_TIMESHEET_BY_MONTH_FAIL = 'ALL_TIMESHEET_BY_MONTH_FAIL'

export const GET_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST = 'GET_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST'
export const GET_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS = 'GET_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS'
export const GET_TIMESHEET_BY_MONTH_AND_YEAR_FAIL = 'GET_TIMESHEET_BY_MONTH_AND_YEAR_FAIL'


export const USER_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST = 'USER_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST'
export const USER_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS = 'USER_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS'
export const USER_TIMESHEET_BY_MONTH_AND_YEAR_FAIL = 'USER_TIMESHEET_BY_MONTH_AND_YEAR_FAIL'
export const USER_TIMESHEET_BY_MONTH_AND_YEAR_RESET = 'USER_TIMESHEET_BY_MONTH_AND_YEAR_RESET'

export const ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST = 'ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST'
export const ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS = 'ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS'
export const ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_FAIL = 'ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_FAIL'


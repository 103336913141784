import dateFormat from "dateformat";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { ToggleSwitch } from "react-dragswitch";
import "react-dragswitch/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  getSelectedTimesheetByUserId,
  getTimesheetsByUserId,
  updateIsSubmittedTimesheets,
  updateSelectedTimesheetHoursByUserId,
} from "../../actions/timesheetActions";
import { allUsers, clearError } from "../../actions/userActions";
import { calculateTotalHoursByMonth, getMonthsString, timesheetHours, checkDateBelongToCurrentMonth } from "../../utils/Data";
import Sidebar from "./Sidebar";

const Timesheets = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, users } = useSelector((state) => state.allUsers);
  const {
    loading: timesheetLoading,
    error: timesheetError,
    timesheets,
    selectedMonth,
    selectedTimesheet,
    selectedUser,
  } = useSelector((state) => state.userTimesheets);
  const { monthTimesheets } = useSelector((state) => state.timesheets);

  useEffect(() => {
    dispatch(allUsers());

    if (error) {
      alert.error(error);
      dispatch(clearError());
    }

    if (timesheetError) {
      alert.error(timesheetError);
      dispatch(clearError());
    }
  }, [dispatch, alert, error, timesheetError]);

  const userSelectionHandler = (user) => {
    dispatch(getTimesheetsByUserId(user._id));
  };

  const findArrayElementByEdit = (array, month) => {
    return array.find((element) => {
      return element.month === month;
    });
  };

  const monthSelectionHandler = (month) => {
    dispatch(getSelectedTimesheetByUserId(month, findArrayElementByEdit(timesheets, month)));
  };

  const findTimesheetObjectByWorkingHours = (workingHour) => {
    return timesheetHours.find((element) => {
      return element.value === workingHour;
    });
  };

  const selectHandler = (e, day, index) => {
    let array = selectedTimesheet.timesheet;
    array[index] = { ...array[index], workingHours: e.value };

    dispatch(updateSelectedTimesheetHoursByUserId(selectedTimesheet));
  };

  const revokeHandler = (value) => {
    if (selectedTimesheet !== undefined && selectedTimesheet !== null) {
      let updatedTimesheet = { ...selectedTimesheet, isSubmitted: value };
      dispatch(updateIsSubmittedTimesheets(updatedTimesheet, timesheets));
    }
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: "10px",
      minHeight: "10px",
      color: 'black',
      border: "1px solid #6e00ff",
      "&:hover": {
        border: state.isFocused ? "1px solid #6e00ff" : "1px solid #6e00ff",
      },
    }),
    option: (base, state) => ({
      ...base,
      fontSize: "10px",
      color: 'black',
      minHeight: "10px",
    }),
  };

  const customStylesDisabled = {
    control: (base, state) => ({
      ...base,
      fontSize: "10px",
      minHeight: "10px",
      border: "1px solid #a6a6a6",
      "&:hover": {
        border: state.isFocused ? "1px solid #a6a6a6" : "1px solid #a6a6a6",
      },
    }),
    option: (base, state) => ({
      ...base,
      fontSize: "10px",
      minHeight: "10px",
    }),
  };

  return (
    <>
      <Sidebar />
      <div className="main-content py-8">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-2">
              <div className="card position bg-white">
                <div className="card-header py-3 bg-search">
                  <span className="h6">Users</span>
                </div>
                <div className="list-group list-group-sm list-group-flush">
                  {users &&
                    users.map((user, i) => {
                      return (
                        <Link
                          key={i}
                          onClick={() => userSelectionHandler(user)}
                          className={
                            "list-group-item list-group-item-action cursor-pointer" +
                            (selectedUser === user._id ? " bg-primary text-white font-weight-bold" : "")
                          }
                        >
                          <div>
                            <span style={{fontWeight: 800, color: monthTimesheets.some(item => item.user._id === user._id ) ? '#198c19': '' }}>{user.name}</span>
                          </div>
                        </Link>
                      );
                    })}
                </div>
              </div>
            </div>

            {timesheets.length > 0 ? (
              <div className="col-6 col-md-2">
                <div className="card position bg-white">
                  <div className="card-header py-3 bg-search">
                    <span className="h6">Months</span>
                  </div>
                  <div className="list-group list-group-sm list-group-flush">
                    {timesheets.map((timesheet, i) => {
                      return (
                        <Link
                          key={i}
                          onClick={() => monthSelectionHandler(timesheet.month)}
                          className={
                            "list-group-item list-group-item-action cursor-pointer" +
                            (selectedMonth === timesheet.month ? " bg-primary text-white font-weight-bold" : "")
                          }
                        >
                          <div>
                            <span>{getMonthsString(timesheet.month, timesheet.year)}</span>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="col-12 col-md-8">
              <h1 className="mb-2">Timesheet</h1>
              {selectedTimesheet && selectedTimesheet.timesheet.length >= 1 && (
                <label>
                  <span>Revoke Timesheet: </span>
                  <ToggleSwitch checked={selectedTimesheet.isSubmitted} onChange={revokeHandler} />
                </label>
              )}
              <div className="card pt-3 pt-md-4">
                <div className="row justify-content-center">
                  {!selectedTimesheet ? (
                    <div>
                      <h3 className="h5 text-center mb-4">No Timesheet Found</h3>
                    </div>
                  ) : (
                    selectedTimesheet.timesheet.map((object, index) => {
                      return (
                        <div className="card col-custom-7 mx-1" key={index}>
                          <div className={(object.workingHours === "H" ? "card-body-timesheet-disabled py-3 w-100" : checkDateBelongToCurrentMonth(object.date, selectedTimesheet && selectedTimesheet.month) ? "card-body-timesheet py-3 w-100" : "card-body-timesheet-disabled py-3 w-100" )}>
                            <p className="lh-100 mb-1">
                              <small>{dateFormat(object.date, "dd/mm/yyyy ddd")}</small>
                            </p>
                            <Select
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                              styles={object.workingHours === "H" ? customStylesDisabled : checkDateBelongToCurrentMonth(object.date, selectedTimesheet && selectedTimesheet.month) ? customStyles : customStylesDisabled }
                              name={index}
                              options={timesheetHours}
                              value={findTimesheetObjectByWorkingHours(object.workingHours)}
                              onChange={(e) => selectHandler(e, object.day, index)}
                              isDisabled={object.workingHours === "H" ? true : checkDateBelongToCurrentMonth(object.date, selectedTimesheet && selectedTimesheet.month) ? false : true}
                            />
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>

              <div className="text-center">
                <p className="text-center">
                  <small>
                    *&nbsp;&nbsp;<b>8</b>: For full working day &nbsp;&nbsp;
                    <b>4</b>: For half working day &nbsp;&nbsp;<b>S</b>: For sick leave &nbsp;&nbsp;
                    <b>V</b>: For vacation/holiday &nbsp;&nbsp;<b>NH</b>: For National holidays &nbsp;&nbsp;
                        <b>PL</b>: For parental leave &nbsp;&nbsp;<b>PCL</b>: For paid compensation leave&nbsp;&nbsp;
                        <b>PH</b>: For paid holiday. &nbsp;&nbsp;
                  </small>
                </p>
                <div className="row justify-content-center">
                <h4>
                      Total Days:{" "} {calculateTotalHoursByMonth(selectedTimesheet &&
                        selectedTimesheet.timesheet, selectedTimesheet &&
                        selectedTimesheet.month)/8}
                    </h4>
                    <h4 className="ml-5">
                      Total Hours:{" "} {calculateTotalHoursByMonth(selectedTimesheet &&
                        selectedTimesheet.timesheet, selectedTimesheet &&
                        selectedTimesheet.month)}
                    </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Timesheets;

import { MDBDataTable } from "mdbreact";
import React, { Fragment, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import dateFormat from "dateformat";

import Loader from "../layouts/Loader";
import Sidebar from "./Sidebar";

import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { allUsers, clearError, deactivateUser } from "../../actions/userActions";
import { DEACTIVATE_USER_RESET } from "../../constants/userConstants";
import MetaData from "../layouts/MetaData";

const UsersList = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, error, allActiveAdminAndUsers } = useSelector((state) => state.allUsers);

  const { loading: deactivateLoading, error: deactivateError, isDeactivated } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(allUsers());

    if (error) {
      alert.error(error);
      dispatch(clearError());
    }

    if (deactivateError) {
      alert.error(deactivateError);
      dispatch(clearError());
    }

    if (isDeactivated) {
      alert.success("User deactivated successfully");
      navigate("/admin/users");
      dispatch({ type: DEACTIVATE_USER_RESET });
    }
  }, [dispatch, alert, error, deactivateError, isDeactivated, navigate, deactivateLoading]);

  const setUsers = () => {
    const data = {
      columns: [
        {
          label: "User ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
        },
        {
          label: "Last Login Time",
          field: "last_login",
          sort: "asc",
        },
        {
          label: "Role",
          field: "role",
          sort: "asc",
        },
        {
          label: "Actions",
          field: "actions",
        },
      ],
      rows: [],
    };

    allActiveAdminAndUsers.forEach((user) => {
      data.rows.push({
        id: user._id,
        name: user.name,
        email: user.email,
        last_login: user.last_login_time ? dateFormat(user.last_login_time, "mmm dd yy HH:MM") : '-',
        role: user.role,

        actions: (
          <Fragment>
            <Link to={`/admin/user/${user._id}`} className="btn btn-primary py-1 px-2">
              <i className="fa fa-pencil-alt"></i>
            </Link>
            <button className="btn btn-danger py-1 px-2 ml-2" onClick={() => deleteUserHandler(user._id)}>
              <i className="fa fa-user-slash"></i>
            </button>
          </Fragment>
        ),
      });
    });

    return data;
  };

  const deleteUserHandler = (id) => {
    if (id === user._id) {
      alert.error("Logged in user cannot be deleted");
    } else {
      dispatch(deactivateUser(id));
    }
  };

  return (
    <Fragment>
      <MetaData title={"All Users"} />
      <Sidebar />
      <div className="main-content py-8">
        <div className="container">
          <h1 className="mb-5">All Users</h1>
          {loading || deactivateLoading ? (
            <Loader />
          ) : (
            <MDBDataTable data={setUsers()} noBottomColumns={true} className="px-3" bordered striped hover responsive />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default UsersList;

import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearError, login } from "../../actions/userActions";
import Loader from "../layouts/Loader";
import MetaData from "../layouts/MetaData";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const { isAuthenticated, loading, error } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/home");
    }

    if (error) {
      alert.error(error);
      dispatch(clearError());
    }
  }, [dispatch, alert, isAuthenticated, error, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={"Login"} />
          <div className="container h-100vh d-flex align-items-center">
            <div className="col">
              <div className="row justify-content-center">
                <div className="col-md-6 col-lg-5 col-xl-5 card p-5 shadow-lg">
                  <div>
                    <div className="mb-5 text-center">
                      <h6 className="h3">Login</h6>
                    </div>
                    <span className="clearfix"></span>
                    <form onSubmit={submitHandler}>
                      <div className="form-group">
                        <label className="form-control-label">Email address</label>
                        <div className="input-group input-group-merge">
                          <input
                            type="email"
                            className="form-control"
                            id="input-email"
                            placeholder="name@example.com"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <label className="form-control-label">Password</label>
                          </div>
                          <div className="mb-2">
                            <Link to="/password/forgot" className="small text-muted text-underline--dashed border-primary">
                              Lost password?
                            </Link>
                          </div>
                        </div>
                        <div className="input-group input-group-merge">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            id="input-password"
                            placeholder="Password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                              <i className="fas fa-eye"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <button type="submit" className="btn btn-block btn-primary">
                          Sign in
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Login;

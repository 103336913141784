import {
    ALL_TIMESHEET_REQUEST,
    ALL_TIMESHEET_SUCCESS,
    ALL_TIMESHEET_FAIL,
    CLEAR_ERROR,
    GET_SELECTED_TIMESHEET,
    UPDATE_SELECTED_TIMESHEET_WORKING_HOURS,
    UPDATE_TIMESHEET_REQUEST,
    UPDATE_TIMESHEET_SUCCESS,
    UPDATE_TIMESHEET_FAIL,
    MESSAGE_RESET,
    CREATE_TIMESHEET_REQUEST,
    CREATE_TIMESHEET_SUCCESS,
    CREATE_TIMESHEET_FAIL,
    USER_TIMESHEET_REQUEST,
    USER_TIMESHEET_SUCCESS,
    USER_TIMESHEET_FAIL,
    GET_SELECTED_TIMESHEET_BY_USER_ID,
    UPDATE_SELECTED_TIMESHEET_WORKING_HOURS_BY_ID,
    UPDATE_ISSUBMITTED_REQUEST,
    UPDATE_ISSUBMITTED_SUCCESS,
    UPDATE_ISSUBMITTED_FAIL,
    ALL_TIMESHEET_BY_MONTH_REQUEST,
    ALL_TIMESHEET_BY_MONTH_SUCCESS,
    ALL_TIMESHEET_BY_MONTH_FAIL,
    GET_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST,
    GET_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS,
    GET_TIMESHEET_BY_MONTH_AND_YEAR_FAIL,
    CREATE_TIMESHEET_SUCCESS_NEW,
    UPDATE_TIMESHEET_SUCCESS_NEW,
    USER_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST,
    USER_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS,
    USER_TIMESHEET_BY_MONTH_AND_YEAR_FAIL,
    UPDATE_ISSUBMITTED_SUCCESS_NEW,
    ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST,
    ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS,
    ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_FAIL
} from '../constants/timesheetConstants'

import axios from 'axios';


export const getTimesheets = () => async (dispatch) => {
    try {
        dispatch({ type: ALL_TIMESHEET_REQUEST })

        const { data } = await axios.get('/api/v1/timesheets/me')

        dispatch({
            type: ALL_TIMESHEET_SUCCESS,
            payload: data
        })

    } catch (e) {
        dispatch({ type: ALL_TIMESHEET_FAIL, payload: e.response.data.message })
    }
}

export const getTimesheetsByMonth = (month) => async (dispatch) => {
    try {
        dispatch({ type: ALL_TIMESHEET_BY_MONTH_REQUEST })

        const { data } = await axios.get(`/api/v1/admin/timesheets/${month}`)

        dispatch({
            type: ALL_TIMESHEET_BY_MONTH_SUCCESS,
            payload: data
        })

    } catch (e) {
        dispatch({ type: ALL_TIMESHEET_BY_MONTH_FAIL, payload: e.response.data.message })
    }
}

export const getTimesheetsByMonthAndYear = (month, year) => async (dispatch) => {
    try {
        dispatch({ type: GET_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST })

        const { data } = await axios.get(`/api/v1/timesheetbymonthandyear/${month}/${year}`)

        data.month = month
        data.year = year

        dispatch({
            type: GET_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS,
            payload: data
        })

    } catch (e) {
        dispatch({ type: GET_TIMESHEET_BY_MONTH_AND_YEAR_FAIL, payload: e.response.data.message })
    }
}

export const updateTimesheets = (timesheet, allTimesheets) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_TIMESHEET_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/timesheet/${timesheet._id}`, timesheet, config)

        const updatedTimesheets = allTimesheets.map((element) => {
            if (element.month === timesheet.month) {
                return data.timesheet
            } 

            return element
        })

        dispatch({
            type: UPDATE_TIMESHEET_SUCCESS,
            payload: data,
            updatedTimesheet: updatedTimesheets
        })

    } catch (e) {
        dispatch({ type: UPDATE_TIMESHEET_FAIL, payload: e.response.data.message })
    }
}

export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR })
}

export const messageReset = () => async (dispatch) => {
    dispatch({ type: MESSAGE_RESET })
}

export const getSelectedTimesheet = (month, timesheet) => async (dispatch) => {
    dispatch({
        type: GET_SELECTED_TIMESHEET,
        month: month,
        timesheet: timesheet
    })
}

export const updateSelectedTimesheetHours = (timesheet) => async (dispatch) => {
    dispatch({
        type: UPDATE_SELECTED_TIMESHEET_WORKING_HOURS,
        timesheet: timesheet
    })
}


export const createTimesheet = (timesheet, allTimesheets) => async (dispatch) => {
    try {

        dispatch({ type: CREATE_TIMESHEET_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/timesheet/new', timesheet, config)

        const updatedTimesheets = allTimesheets.map((element) => {
            if (element.month === timesheet.month) {
                return data.timesheet
            } 

            return element
        })

        dispatch({
            type: CREATE_TIMESHEET_SUCCESS,
            payload: data,
            updatedTimesheet: updatedTimesheets
        })


    } catch (error) {
        dispatch({
            type: CREATE_TIMESHEET_FAIL,
            payload: error.response.data.message
        })
    }
}

export const createTimesheetNew = (timesheet) => async (dispatch) => {
    try {

        dispatch({ type: CREATE_TIMESHEET_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/timesheet/new', timesheet, config)

        dispatch({
            type: CREATE_TIMESHEET_SUCCESS_NEW,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: CREATE_TIMESHEET_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateTimesheetNew = (timesheet) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_TIMESHEET_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/timesheet/${timesheet._id}`, timesheet, config)

        dispatch({
            type: UPDATE_TIMESHEET_SUCCESS_NEW,
            payload: data,
        })

    } catch (e) {
        dispatch({ type: UPDATE_TIMESHEET_FAIL, payload: e.response.data.message })
    }
}

export const getTimesheetsByUserId = (id) => async (dispatch) => {
    try {
        dispatch({ type: USER_TIMESHEET_REQUEST })

        const { data } = await axios.get(`/api/v1/admin/timesheets/user/${id}`)

        dispatch({
            type: USER_TIMESHEET_SUCCESS,
            payload: data,
            userId: id
        })

    } catch (e) {
        dispatch({ type: USER_TIMESHEET_FAIL, payload: e.response.data.message })
    }
}

export const getTimesheetByUserWithMonthAndYear = (id, month, year) => async (dispatch) => {
    try {
        dispatch({ type: USER_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST })

        const { data } = await axios.get(`/api/v1/admin/timesheets/user/${id}/${month}/${year}`)

        data.month = month
        data.year = year

        dispatch({
            type: USER_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS,
            payload: data
        })

    } catch (e) {
        dispatch({ type: USER_TIMESHEET_BY_MONTH_AND_YEAR_FAIL, payload: e.response.data.message })
    }
}

export const getAdminTimesheetsByMonthAndYear = (month, year) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST })

        const { data } = await axios.get(`/api/v1/admin/timesheetbymonthandyear/${month}/${year}`)

        data.month = month
        data.year = year

        dispatch({
            type: ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS,
            payload: data
        })

    } catch (e) {
        dispatch({ type: ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_FAIL, payload: e.response.data.message })
    }
}

export const getSelectedTimesheetByUserId = (month, timesheet) => async (dispatch) => {
    dispatch({
        type: GET_SELECTED_TIMESHEET_BY_USER_ID,
        month: month,
        timesheet: timesheet
    })
}

export const updateSelectedTimesheetHoursByUserId = (timesheet) => async (dispatch) => {
    dispatch({
        type: UPDATE_SELECTED_TIMESHEET_WORKING_HOURS_BY_ID,
        timesheet: timesheet
    })
}

export const updateIsSubmittedTimesheets = (timesheet, allTimesheets) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_ISSUBMITTED_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/timesheet/revoke/${timesheet._id}`, timesheet, config)

        const updatedTimesheets = allTimesheets.map((element) => {
            if (element.month === timesheet.month) {
                return data.timesheet
            } 

            return element
        })

        dispatch({
            type: UPDATE_ISSUBMITTED_SUCCESS,
            payload: data,
            updatedTimesheet: updatedTimesheets
        })

    } catch (e) {
        dispatch({ type: UPDATE_ISSUBMITTED_FAIL, payload: e.response.data.message })
    }
}

export const updateIsSubmittedTimesheetsNew = (timesheet) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_ISSUBMITTED_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/timesheet/revoke/${timesheet._id}`, timesheet, config)

        dispatch({
            type: UPDATE_ISSUBMITTED_SUCCESS_NEW,
            payload: data,
        })

    } catch (e) {
        dispatch({ type: UPDATE_ISSUBMITTED_FAIL, payload: e.response.data.message })
    }
}

import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { clearError, forgotPassword } from "../../actions/userActions";
import MetaData from "../layouts/MetaData";
import { useNavigate } from "react-router-dom";
import { FORGET_PASSWORD_RESET } from "../../constants/userConstants";

const ForgotPaaword = () => {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();

  const { error, loading, message } = useSelector((state) => state.forgotPassword);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearError());
    }
    if (message) {
      alert.success(message);
      navigate("/");
      dispatch({
        type: FORGET_PASSWORD_RESET,
      });
    }
  }, [dispatch, alert, error, message]);

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.set("email", email);

    dispatch(forgotPassword(formData));
  };

  return (
    <Fragment>
      <MetaData title={"Forgot Password"} />
      <div className="container h-100vh d-flex align-items-center">
        <div className="col">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-5 col-xl-5 card p-5 shadow-lg">
              <div>
                <div className="mb-5 text-center">
                  <h6 className="h3">Password reset</h6>
                </div>
                <span className="clearfix"></span>
                <form onSubmit={submitHandler}>
                  <div className="form-group">
                    <label className="form-control-label">Email address</label>
                    <div className="input-group input-group-merge">
                      <input
                        type="email"
                        className="form-control"
                        id="input-email"
                        placeholder="name@example.com"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <button type="submit" className="btn btn-block btn-primary" disabled={loading ? true : false}>
                      Reset password
                    </button>
                  </div>
                </form>
                <div className="mt-4 text-center">
                  <small>Or </small>
                  <Link to="/" className="small font-weight-bold">
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotPaaword;

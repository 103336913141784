import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../actions/userActions";
import logo from "../../assets/img/ThoughtLabs.svg";
import { LOGOUT_RESET } from "../../constants/userConstants";

const Header = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loading, success } = useSelector((state) => state.auth);
  const [currentLocation, setCurrentLocation] = useState("");

  useEffect(() => {
    setCurrentLocation(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    if (success) {
      alert.success("Logged out Successfully");
      // dispatch({
      //   type: LOGOUT_RESET,
      // });
      //navigate("/");
    }
  }, [loading, success]);

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <Fragment>
      <header className="header fixed-top shadow">
        <nav id="navbar-main" className="navbar navbar-expand-lg navbar-light bg-white">
          <div className="container px-lg-0">
            <Link className="navbar-brand" to="/">
              <img alt="ThoughtLabs" src={logo} id="navbar-logo" style={{ height: "50px" }} />
            </Link>
            {currentLocation === "/" ||
            currentLocation === "/home" ||
            currentLocation === "/profile" ||
            currentLocation === "/password/update" ||
            currentLocation === "/password/forgot" ? (
              <></>
            ) : (
              <button className="sidenav-toggler mr-3" type="button" data-action="sidenav-pin" data-target="#sidenav-main">
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                </div>
              </button>
            )}

            {user ? (
              <ul className="navbar-nav align-items-lg-center ml-lg-auto">
                <li className="nav-item dropdown">
                  <a className="nav-link pr-0" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-user-circle"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-sm dropdown-menu-left">
                    {user && user.role === "admin" ? (
                      <Link className="dropdown-item" to="/dashboard">
                        <i className="fas fa-list-ul"></i>Dashboard
                      </Link>
                    ) : (
                      <></>
                    )}
                    <Link className="dropdown-item" to="/profile">
                      <i className="fas fa-user"></i>Profile
                    </Link>
                    <div className="dropdown-divider" role="presentation"></div>
                    <Link className="dropdown-item" onClick={logoutHandler}>
                      <i className="fas fa-sign-out-alt"></i>Sign out
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <span className="h5 d-none d-lg-inline">{user.name}</span>
                </li>
              </ul>
            ) : (
              !loading && <></>
            )}
          </div>
        </nav>
      </header>
    </Fragment>
  );
};

export default Header;

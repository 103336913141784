import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAdminTimesheetsByMonthAndYear } from "../../actions/timesheetActions";
import { allUsers, clearError } from "../../actions/userActions";
import { getCurrentMonthString, getCurrentMonth, getCurrentYear } from "../../utils/Data";
import Loader from "../layouts/Loader";
import Sidebar from "./Sidebar";

const Dashboard = () => {
  const { loading, error, users } = useSelector((state) => state.allUsers);
  const {  monthAndYearTimesheets } = useSelector((state) => state.timesheets);
  const getCurrentMonthStr = getCurrentMonthString();

  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    dispatch(allUsers());
    dispatch(getAdminTimesheetsByMonthAndYear(getCurrentMonth(), getCurrentYear()));
    //dispatch(getTimesheetsByMonth(new Date(Date.now()).getMonth() + 1));
    if (error) {
      alert.error(error);
      dispatch(clearError());
    }
  }, [dispatch, alert, error]);

  return (
    <>
      <Sidebar />

      {loading ? (
        <Loader />
      ) : (
        <div className="main-content py-8">
          <div className="container">
            <h1 className="mb-5">Dashboard</h1>
            <div className="row">
              <div className="col-6 d-flex">
                <div className="card border-0 text-center py-4 hover-translate-y-n10 shadow-lg w-100">
                  <div className="card-body">
                    <h5 className="h4">Total Users - {users && users.length}</h5>
                    <Link to="/admin/users" className="d-inline-block link link-underline-primary text-primary mt-4">
                      View Details
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-6 d-flex">
                <div className="card border-0 text-center py-4 hover-translate-y-n10 shadow-lg w-100">
                  <div className="card-body">
                    <h5 className="h4 ">
                      {`Timesheets received in ${getCurrentMonthStr}`} - {monthAndYearTimesheets && monthAndYearTimesheets.length}
                    </h5>
                    <Link to="/admin/timesheetList" className="d-inline-block link link-underline-primary text-primary mt-4">
                      View Details
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;

import dateFormat from "dateformat";
import Holidays from 'date-holidays'

export const timesheetHours = [
    { value: '8', label: '8' },
    { value: '4', label: '4' },
    { value: 'S', label: 'S' },
    { value: 'V', label: 'V' },
    { value: 'NH', label: 'NH' },
    { value: 'H', label: 'H', isDisabled: true },
    { value: 'PL', label: 'PL' },
    { value: 'PCL', label: 'PCL' },
    { value: 'PH', label: 'PH' },
]


export const getCurrentMonthsDaysList = () => {
    var hd = new Holidays()
    const currentDate = new Date(Date.now())
    const date = new Date(currentDate.getUTCFullYear(), currentDate.getMonth(), 1);

    const dates = [];
    hd.init('BE')

    while (date.getMonth() === currentDate.getMonth()) {
        const finalDate = new Date(date)
        const formattedDate = dateFormat(finalDate, "ddd mmm dd yyyy")
        const isHoliday = hd.isHoliday(finalDate)
        const workingHours = formattedDate.split(' ')[0] === "Sat" || formattedDate.split(' ')[0] === "Sun" ? "H" : isHoliday && isHoliday[0].type === 'public' ? "NH" : "8"
        const timesheetObject = {
            "workingHours": workingHours,
            "date": formattedDate,
            "day": parseInt(dateFormat(finalDate, "dd"))
        }

        dates.push(timesheetObject);
        date.setDate(date.getDate() + 1);
    }
    const timesheetObject = {
        "month": currentDate.getMonth() + 1,
        "isSubmitted": false,
        "timesheet": dates,
        "year": currentDate.getUTCFullYear()
    }

    return timesheetObject;
}

export const getCurrentMonthsDaysList2 = () => {
    var hd = new Holidays()
    const currentDate = new Date(Date.now())
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const weekdayOfFirstDay = date.getDay();

    const dates = [];
    hd.init('BE')

    for (let day = 0; day < 42; day++) {
        if (day === 0 && weekdayOfFirstDay === 0) {
            date.setDate(date.getDate() - 6);
        } else if (day === 0) {
            date.setDate(date.getDate() + (day - weekdayOfFirstDay + 1));
        } else {
            date.setDate(date.getDate() + 1);
        }

        const finalDate = new Date(date)
        const formattedDate = dateFormat(finalDate, "ddd mmm dd yyyy")
        const isHoliday = hd.isHoliday(finalDate)
        const workingHours = formattedDate.split(' ')[0] === "Sat" || formattedDate.split(' ')[0] === "Sun" ? "H" : isHoliday && isHoliday[0].type === 'public' ? "NH" : "8"
        const timesheetObject = {
            "workingHours": workingHours,
            "date": formattedDate,
            "day": parseInt(dateFormat(finalDate, "dd"))
        }
        dates.push(timesheetObject);
    }

    const timesheetObject = {
        "month": currentDate.getMonth() + 1,
        "isSubmitted": false,
        "timesheet": dates,
        "year": currentDate.getUTCFullYear()
    }

    return timesheetObject;
}

export const getMonthsString = (month, year) => {

    const date = new Date();
    date.setMonth(month - 1);

    const shortMonth = date.toLocaleString('en-US', {
        month: 'short',
    })

    return `${shortMonth} ${year}`
}

export const getCurrentMonthString = () => {
    const currentDate = new Date(Date.now())
    const shortMonth = currentDate.toLocaleString('en-US', {
        month: 'short',
    })
    return `${shortMonth}`
}

export const getDayString = (date) => {

    const finalDate = new Date(date)
    const formattedDate = dateFormat(finalDate, "ddd mmm dd yyyy")
    return formattedDate.split(' ')[0]
}

export const checkForCurrentMonthAndCreateTimeSheet = (timesheets) => {
    const currentDate = new Date(Date.now())
    const value = timesheets.find((element) => {
        return element.month === currentDate.getMonth() + 1
    })

    if (value === null || value === undefined) {
        timesheets.push(getCurrentMonthsDaysList2())
        return timesheets
    }

    return timesheets
}

export const calculateTotalHours = (timesheets) => {
    if (timesheets !== undefined && timesheets !== null && timesheets.length > 0) {
        let sum = timesheets.reduce(function (prev, current) {
            return current.workingHours === "8" || current.workingHours === "4" ? prev + +Number(current.workingHours) : prev
        }, 0);
        return sum
    }
}

export const calculateTotalHoursByMonth = (timesheet, month) => {
    if (timesheet !== undefined && timesheet !== null && timesheet.length > 0) {
        const updatedtimesheet = timesheet.filter((item) => new Date(item.date).getMonth() + 1 == month);
        let sum = updatedtimesheet.reduce(function (prev, current) {
            return current.workingHours === "8" || current.workingHours === "4" ? prev + +Number(current.workingHours) : prev
        }, 0);
        return sum
    }
}

export const checkDateBelongToCurrentMonth = (date, month) => {
    const finalDate = new Date(date)

    return month == finalDate.getMonth() + 1
}

export const sendOnlyCurrentMonthDataToServer = (timesheetToSend) => {
    const currentDate = new Date(Date.now())
    const timesheetList = timesheetToSend.timesheet.filter((item) => new Date(item.date).getMonth() + 1 == currentDate.getMonth() + 1);
    timesheetToSend.timesheet = timesheetList
    return timesheetToSend
}

export const sendOnlySelectedMonthDataToServer = (timesheetToSend) => {
    const timesheetList = timesheetToSend.timesheet.filter((item) => new Date(item.date).getMonth() + 1 == timesheetToSend.month);
    timesheetToSend.timesheet = timesheetList
    return timesheetToSend
}

export const getMonthsList = () => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    const monthList = []

    for (let month = 1; month <= 12; month++) {
        const date = new Date(); 
        date.setMonth(month-1)
        const monthStr = date.toLocaleString('default', { month: 'short' }).toUpperCase();
        monthList.push({ 
        value: month, 
        label: `${monthStr} ${currentYear}`, 
        key: monthStr, 
        isSelected: month === currentMonth ? true : false,
        isDisabled: currentMonth < month ? true : false,
        year: currentYear
     })
    }

    return monthList
}

export const getCurrentMonth = () => {
    return new Date().getMonth() + 1
}

export const getCurrentYear = () => {
    return new Date().getFullYear()
}

export const getEmptyTimesheetWithMonthAndYear = (month, year) => {
    var hd = new Holidays()
    const date = new Date(year, month-1, 1);
    const weekdayOfFirstDay = date.getDay();

    const dates = [];
    hd.init('BE')

    for (let day = 0; day < 42; day++) {
        if (day === 0 && weekdayOfFirstDay === 0) {
            date.setDate(date.getDate() - 6);
        } else if (day === 0) {
            date.setDate(date.getDate() + (day - weekdayOfFirstDay + 1));
        } else {
            date.setDate(date.getDate() + 1);
        }

        const finalDate = new Date(date)
        const formattedDate = dateFormat(finalDate, "ddd mmm dd yyyy")
        const isHoliday = hd.isHoliday(finalDate)
        const workingHours = formattedDate.split(' ')[0] === "Sat" || formattedDate.split(' ')[0] === "Sun" ? "H" : isHoliday && isHoliday[0].type === 'public' ? "NH" : "8"
        const timesheetObject = {
            "workingHours": workingHours,
            "date": formattedDate,
            "day": parseInt(dateFormat(finalDate, "dd"))
        }
        dates.push(timesheetObject);
    }

    const timesheetObject = {
        "month": month,
        "isSubmitted": false,
        "timesheet": dates,
        "year": year
    }

    return timesheetObject;
}


export const getFilledTimesheetWithMonthAndYear = (month, year, timesheet) => {
    var hd = new Holidays()
    const date = new Date(year, month-1, 1);
    const weekdayOfFirstDay = date.getDay();
    const dates = [];
    hd.init('BE')
    for (let day = 0; day < 42; day++) {
        if (day === 0 && weekdayOfFirstDay === 0) {
            date.setDate(date.getDate() - 6);
        } else if (day === 0) {
            date.setDate(date.getDate() + (day - weekdayOfFirstDay + 1));
        } else {
            date.setDate(date.getDate() + 1);
        }

        const finalDate = new Date(date)
        const formattedDate = dateFormat(finalDate, "ddd mmm dd yyyy")
        const isHoliday = hd.isHoliday(finalDate)
        const workingHours = formattedDate.split(' ')[0] === "Sat" || formattedDate.split(' ')[0] === "Sun" ? "H" : isHoliday && isHoliday[0].type === 'public' ? "NH" : "8"
        
        var existingWorkingHours = timesheet.timesheet.find((item => dateFormat(item.date, "ddd mmm dd yyyy") === formattedDate))
        
        const timesheetObject = {
            "workingHours": existingWorkingHours ? existingWorkingHours.workingHours :workingHours,
            "date": formattedDate,
            "day": parseInt(dateFormat(finalDate, "dd"))
        }
        dates.push(timesheetObject);
    }

    timesheet.timesheet = dates
    
    return timesheet;

}

export const getActiveUsers = (users) => {
    const usersList = users.filter((user) => user.role === 'user' && user.isDisabled === false );
    return usersList
}

export const getDeactiveUsers = (users) => {
    const usersList = users.filter((user) => user.isDisabled === true );
    return usersList
}

export const getActiveBothAdminAndUser = (users) => {
    const usersList = users.filter((user) => user.isDisabled === false );
    return usersList
}

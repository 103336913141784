import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import defaultAvatar from "../../assets/img/default_avatar.png";
import Loader from "../layouts/Loader";
import MetaData from "../layouts/MetaData";

const Profile = () => {
  const { user, loading } = useSelector((state) => state.auth);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={"Profile"} />
          <div className="py-8">
            <div className="container">
              <div className="row row-grid justify-content-center">
                <div className="col-lg-9">
                  <div className="row justify-content-center">
                    <div className="col-md-3">
                      <img className="img-fluid w-25 w-md-75 mt-md-3 mb-4 mb-md-0" src={defaultAvatar} alt={user && user.name} />
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-control-label">Full Name</label>
                        <input className="form-control" type="text" value={user && user.name} disabled />
                      </div>
                      <div className="form-group">
                        <label className="form-control-label">Email Address</label>
                        <input className="form-control" type="text" value={user && user.email} disabled />
                      </div>
                      <div className="mt-4">
                        <Link to="/password/update" className="btn btn-sm btn-primary">
                          Change Password
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Profile;

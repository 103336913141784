import React, { Fragment } from "react";

const Footer = () => {
  return (
    <Fragment>
      <footer className="footer p-0 footer-light position-absolute bottom-0 w-100">
        <div className="container">
          <div className="py-4 text-center">
            <p className="text-sm mb-0">
              &copy; {new Date().getFullYear()}{" "}
              <a
                href="https://thoughtlabs.be/"
                target="_blank"
                rel="noreferrer"
              >
                Thoughtlabs
              </a>
              . All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;

import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { clearError, resetPassword } from "../../actions/userActions";
import Loader from "../layouts/Loader";
import MetaData from "../layouts/MetaData";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const params = useParams();

  const { error, loading, success } = useSelector((state) => state.forgotPassword);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearError());
    }

    if (success) {
      alert.success("Password reset successfully");
      navigate("/");
    }
  }, [dispatch, alert, error, success, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.set("password", newPassword);
    formData.set("confirmPassword", confirmPassword);

    dispatch(resetPassword(params.token, formData));
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={"Reset Password"} />
          <div className="py-8">
            <div className="container">
              <div className="row row-grid justify-content-center">
                <div className="col-md-5">
                  <form onSubmit={submitHandler}>
                    {/* Password */}
                    <div className="actions-toolbar py-2 mb-4">
                      <h5>Reset password</h5>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-control-label">New password</label>
                          <input className="form-control" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-control-label">Confirm password</label>
                          <input
                            className="form-control"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <button type="submit" className="btn btn-sm btn-block btn-primary" disabled={loading ? true : false}>
                        Set password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
};

export default ResetPassword;

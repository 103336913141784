import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Sidebar from "./Sidebar";

import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearError, getUserDetails, updateUser } from "../../actions/userActions";
import { UPDATE_USER_RESET } from "../../constants/userConstants";
import MetaData from "../layouts/MetaData";

const UpdateUser = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { error, isUpdate } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.userDetails);

  const userId = params.id;

  useEffect(() => {
    if (user && user._id !== userId) {
      dispatch(getUserDetails(userId));
    } else {
      setName(user.name);
      setEmail(user.email);
      setRole(user.role);
    }

    if (error) {
      alert.error(error);
      dispatch(clearError());
    }

    if (isUpdate) {
      alert.success("User updated successfully");

      navigate("/admin/users");

      dispatch({
        type: UPDATE_USER_RESET,
      });
    }
  }, [dispatch, alert, error, navigate, isUpdate, userId, user]);

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.set("name", name);
    formData.set("email", email);
    formData.set("role", role);

    dispatch(updateUser(user._id, formData));
  };

  return (
    <Fragment>
      <MetaData title={"Update User"} />
      <Sidebar />
      <div className="main-content py-8">
        <div className="container">
          <h1 className="mb-5">Update User</h1>
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <form className="shadow-lg px-3 px-md-8 py-5" onSubmit={submitHandler}>
                <div className="form-group">
                  <label className="form-control-label">Name</label>
                  <div className="input-group input-group-merge">
                    <input
                      type="text"
                      className="form-control"
                      id="input-name"
                      placeholder="John Doe"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-control-label">Email address</label>
                  <div className="input-group input-group-merge">
                    <input
                      type="email"
                      className="form-control"
                      id="input-email"
                      placeholder="name@example.com"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-control-label">Role</label>
                  <div className="input-group input-group-merge">
                    <select id="input-role" className="form-control" name="role" value={role} onChange={(e) => setRole(e.target.value)}>
                      <option value="user">User</option>
                      <option value="admin">Admin</option>
                    </select>
                  </div>
                </div>

                <div className="mt-5 text-center">
                  <button type="submit" className="btn btn-primary">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateUser;

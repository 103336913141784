import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    FORGET_PASSWORD_REQUEST,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAIL,
    FORGET_PASSWORD_RESET,
    NEW_PASSWORD_REQUEST,
    NEW_PASSWORD_SUCCESS,
    NEW_PASSWORD_FAIL,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_RESET,
    UPDATE_PASSWORD_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    LOGOUT_RESET,
    CLEAR_ERROR,
    ADMIN_USERS_REQUEST,
    ADMIN_USERS_SUCCESS,
    ADMIN_USERS_FAIL,
    NEW_USER_REQUEST,
    NEW_USER_SUCCESS,
    NEW_USER_RESET,
    NEW_USER_FAIL,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_RESET,
    DELETE_USER_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_RESET,
    UPDATE_USER_FAIL,
    DEACTIVATE_USER_REQUEST,
    DEACTIVATE_USER_SUCCESS,
    DEACTIVATE_USER_FAIL,
    DEACTIVATE_USER_RESET,
    REACTIVATE_USER_REQUEST,
    REACTIVATE_USER_SUCCESS,
    REACTIVATE_USER_FAIL,
    REACTIVATE_USER_RESET
} from '../constants/userConstants'
import { getActiveUsers, getDeactiveUsers, getActiveBothAdminAndUser } from '../utils/Data'

export const authReducers = (state = { user: [] }, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
        case REGISTER_USER_REQUEST:
        case LOAD_USER_REQUEST:
            return {
                loading: true,
                isAuthenticated: false
            }

        case LOGIN_SUCCESS:
        case REGISTER_USER_SUCCESS:
        case LOAD_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: action.payload
            }

        case LOGOUT_SUCCESS:
            return {
                loading: false,
                isAuthenticated: false,
                user: null,
                success: action.payload
            }

        case LOAD_USER_FAIL:
            return {
                loading: false,
                isAuthenticated: false,
                user: null,
                error: action.payload
            }

        case LOGIN_FAIL:
        case REGISTER_USER_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: null,
                error: action.payload
            }

        case LOGOUT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case LOGOUT_RESET:
            return {
                success: false
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const userReducers = (state = {}, action) => {
    switch (action.type) {

        case UPDATE_PASSWORD_REQUEST:
        case UPDATE_USER_REQUEST:
        case DELETE_USER_REQUEST:
        case REACTIVATE_USER_REQUEST:
        case DEACTIVATE_USER_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case UPDATE_USER_SUCCESS:
        case UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdate: action.payload
            }

        case DELETE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }

        case DEACTIVATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeactivated: action.payload
            }

        case REACTIVATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isReactivated: action.payload
            }

        case UPDATE_USER_FAIL:
        case UPDATE_PASSWORD_FAIL:
        case DEACTIVATE_USER_FAIL:
        case REACTIVATE_USER_FAIL:
        case DELETE_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case UPDATE_USER_RESET:
        case UPDATE_PASSWORD_RESET:
            return {
                ...state,
                isUpdate: false
            }

        case DELETE_USER_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case DEACTIVATE_USER_RESET:
            return {
                ...state,
                isDeactivated: false
            }

        case REACTIVATE_USER_RESET:
            return {
                ...state,
                isReactivated: false
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const forgotPasswordReducers = (state = {}, action) => {
    switch (action.type) {

        case FORGET_PASSWORD_REQUEST:
        case NEW_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }

        case NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload
            }

        case FORGET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload
            }
        case FORGET_PASSWORD_RESET:
            return {
                ...state,
                message: null
            }

        case FORGET_PASSWORD_FAIL:
        case NEW_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const allUsersReducers = (state = { users: [] }, action) => {
    switch (action.type) {


        case ADMIN_USERS_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case ADMIN_USERS_SUCCESS:
            const activeUsers = getActiveUsers(action.payload)
            const deactiveUsers = getDeactiveUsers(action.payload)
            const allActiveAdminAndUsers = getActiveBothAdminAndUser(action.payload)
            return {
                ...state,
                loading: false,
                users: action.payload,
                activeUsers: activeUsers,
                deactiveUsers: deactiveUsers,
                allActiveAdminAndUsers: allActiveAdminAndUsers
            }


        case ADMIN_USERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const newUserReducers = (state = { users: [] }, action) => {
    switch (action.type) {

        case NEW_USER_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case NEW_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.user,
                success: action.payload.success
            }


        case NEW_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case NEW_USER_RESET:
            return {
                ...state,
                success: false,
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const userDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {

        case USER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case USER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload
            }

        case USER_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}
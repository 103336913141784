import React, { Fragment, useState, useEffect } from 'react'
import { getMonthsList, timesheetHours, getCurrentMonth, getCurrentYear, checkDateBelongToCurrentMonth, calculateTotalHoursByMonth, sendOnlyCurrentMonthDataToServer, sendOnlySelectedMonthDataToServer } from "./utils/Data";
import { Link } from "react-router-dom";
import MetaData from "./components/layouts/MetaData";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import {
    clearError,
    getTimesheetsByMonthAndYear,
    updateSelectedTimesheetHours,
    messageReset,
    createTimesheetNew,
    updateTimesheetNew,
} from "./actions/timesheetActions";
import Loader from './components/layouts/Loader';
import dateFormat from "dateformat";
import Select from "react-select";

const HomeNew = () => {

    const dispatch = useDispatch();
    const alert = useAlert();
    const { loading, error, selectedTimesheet, message } = useSelector((state) => state.timesheets);

    const [monthList, setMonthList] = useState(getMonthsList())

    useEffect(() => {
        dispatch(getTimesheetsByMonthAndYear(getCurrentMonth(), getCurrentYear()));
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearError());
        }

        if (message) {
            alert.success(message);
            dispatch(messageReset());
        }

    }, [dispatch, message, error, alert]);

    const monthSelectionHandler = (monthValue, index) => {
        const monthStr = [...monthList];
        const updatedArray = monthStr.map(month => month.value === monthValue.value ? { ...month, isSelected: true } : { ...month, isSelected: false })
        setMonthList(updatedArray)

        dispatch(getTimesheetsByMonthAndYear(monthValue.value, monthValue.year));
    }

    const findTimesheetObjectByWorkingHours = (workingHour) => {
        return timesheetHours.find((element) => {
            return element.value === workingHour;
        });
    };

    const selectHandler = (e, day, index) => {
        let array = selectedTimesheet.timesheet;
        array[index] = { ...array[index], workingHours: e.value };

        dispatch(updateSelectedTimesheetHours(selectedTimesheet));
    };

    const submitHandler = () => {
        if (selectedTimesheet !== undefined && selectedTimesheet !== null) {
            let updatedTimesheet = { ...selectedTimesheet, isSubmitted: true };
            if (updatedTimesheet._id !== null && updatedTimesheet._id !== undefined) {
                dispatch(updateTimesheetNew(sendOnlySelectedMonthDataToServer(updatedTimesheet)));
            } else {
                dispatch(createTimesheetNew(sendOnlySelectedMonthDataToServer(updatedTimesheet)));
            }
        }
    };

    const customStyles = {
        control: (base, state) => ({
            ...base,
            fontSize: "10px",
            minHeight: "10px",
            color: 'black',
            border: "1px solid #6e00ff",
            "&:hover": {
                border: state.isFocused ? "1px solid #6e00ff" : "1px solid #6e00ff",
            },
        }),
        option: (base, state) => ({
            ...base,
            fontSize: "10px",
            color: 'black',
            minHeight: "10px",
        }),
    };

    const customStylesDisabled = {
        control: (base, state) => ({
            ...base,
            fontSize: "10px",
            minHeight: "10px",
            border: "1px solid #a6a6a6",
            "&:hover": {
                border: state.isFocused ? "1px solid #a6a6a6" : "1px solid #a6a6a6",
            },
        }),
        option: (base, state) => ({
            ...base,
            fontSize: "10px",
            minHeight: "10px",
        }),
    };

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <MetaData title={"Home"} />
                    <div className="py-8">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card position bg-white">
                                        <div className="card-header py-3 bg-search">
                                            <span className="h6">Months</span>
                                        </div>
                                        <div className="list-group list-group-sm list-group-flush">
                                            {monthList.map((month, i) => {
                                                return (
                                                    <Link
                                                        key={i}
                                                        onClick={() => monthSelectionHandler(month, i)}
                                                        className={month.isDisabled ? "list-group-disabled" :
                                                            "list-group-item list-group-item-action cursor-pointer" +
                                                            (month.isSelected ? " bg-primary text-white font-weight-bold" : "")
                                                        }
                                                    >
                                                        <div>
                                                            <span>{month.label}</span>
                                                        </div>
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <h1 className="mb-2">Timesheet</h1>
                                    <div className="card pt-3 pt-md-4">
                                        <div className="row justify-content-center">
                                            {selectedTimesheet &&
                                                selectedTimesheet.timesheet.map((object, index) => {
                                                    return (
                                                        <div className="card col-custom-7 mx-1" key={index}>
                                                            <div className={(object.workingHours === "H" ? "card-body-timesheet-disabled py-3 w-100" : object.workingHours === "NH" ? "card-body-timesheet-national-holiday py-3 w-100" : object.workingHours === "V" || object.workingHours === "PL" || object.workingHours === "PCL" || object.workingHours === "PH" || object.workingHours === "S"  ? "card-body-timesheet-vacation py-3 w-100" : selectedTimesheet && selectedTimesheet.isSubmitted === true ? "card-body-timesheet-disabled py-3 w-100" : checkDateBelongToCurrentMonth(object.date, selectedTimesheet && selectedTimesheet.month) ? "card-body-timesheet py-3 w-100" : "card-body-timesheet-disabled py-3 w-100")}>
                                                                <p className="lh-100 mb-3">
                                                                    <small>{dateFormat(object.date, "dd/mm/yyyy dddd")}</small>
                                                                </p>
                                                                <Select
                                                                    components={{
                                                                        DropdownIndicator: () => null,
                                                                        IndicatorSeparator: () => null,
                                                                    }}
                                                                    styles={object.workingHours === "H" ? customStylesDisabled : selectedTimesheet && selectedTimesheet.isSubmitted === true ? customStylesDisabled : checkDateBelongToCurrentMonth(object.date, selectedTimesheet && selectedTimesheet.month) ? customStyles : customStylesDisabled}
                                                                    name={index}
                                                                    options={timesheetHours}
                                                                    value={findTimesheetObjectByWorkingHours(object.workingHours)}
                                                                    onChange={(e) => selectHandler(e, object.day, index)}
                                                                    isDisabled={object.workingHours === "H" ? true : selectedTimesheet && selectedTimesheet.isSubmitted === true ? true : checkDateBelongToCurrentMonth(object.date, selectedTimesheet && selectedTimesheet.month) ? false : true}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <p className="text-center">
                                            <small>
                                                *&nbsp;&nbsp;<b>8</b>: For full working day &nbsp;&nbsp;
                                                <b>4</b>: For half working day &nbsp;&nbsp;<b>S</b>: For sick leave &nbsp;&nbsp;
                                                <b>V</b>: For vacation/holiday &nbsp;&nbsp;<b>NH</b>: For National holidays &nbsp;&nbsp;
                                                <b>PL</b>: For parental leave &nbsp;&nbsp;<b>PCL</b>: For paid compensation leave&nbsp;&nbsp;
                                                <b>PH</b>: For paid holiday. &nbsp;&nbsp;
                                            </small>
                                        </p>
                                        <div className="row justify-content-center">
                                            <h4>
                                                Total Days:{" "} {calculateTotalHoursByMonth(selectedTimesheet &&
                                                    selectedTimesheet.timesheet, selectedTimesheet &&
                                                selectedTimesheet.month) / 8}
                                            </h4>
                                            <h4 className="ml-5">
                                                Total Hours:{" "} {calculateTotalHoursByMonth(selectedTimesheet &&
                                                    selectedTimesheet.timesheet, selectedTimesheet &&
                                                selectedTimesheet.month)}
                                            </h4>
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={submitHandler}
                                            disabled={selectedTimesheet && selectedTimesheet.isSubmitted === true ? true : false}
                                        >
                                            Submit Timesheet
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default HomeNew
import { MDBDataTable } from "mdbreact";
import React, { Fragment, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import dateFormat from "dateformat";

import Loader from "../layouts/Loader";
import Sidebar from "./Sidebar";

import { useAlert } from "react-alert";
import { allUsers, clearError } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../layouts/MetaData";

const TimesheetList = () => {

    const alert = useAlert();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, error, users, activeUsers } = useSelector((state) => state.allUsers);
    const { monthAndYearTimesheets } = useSelector((state) => state.timesheets);

    useEffect(() => {
        dispatch(allUsers());

        if (error) {
            alert.error(error);
            dispatch(clearError());
        }

    }, [dispatch, alert, error, navigate]);

    const setUsers = () => {
        const data = {
            columns: [
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                },
                {
                    label: "Last Login Time",
                    field: "last_login",
                    sort: "asc",
                },
                {
                    label: "Current Month Submitted",
                    field: "current_month_submitted",
                    sort: "asc",
                },
                {
                    label: "Actions",
                    field: "actions",
                },
            ],
            rows: [],
        };

        activeUsers.forEach((user) => {
            data.rows.push({
                name: user.name,
                last_login: user.last_login_time ? dateFormat(user.last_login_time, "mmm dd yy HH:MM") : '-',
                current_month_submitted: monthAndYearTimesheets.some(item => item.user && item.user._id === user._id) ? 'Submitted' : 'Pending',

                actions: (
                    <Fragment>
                        <Link to={`/admin/timesheets/${user._id}`} className="btn btn-primary py-1 px-2">
                            <i className="fa fa-eye"></i>
                        </Link>
                    </Fragment>
                ),
            });
        });

        return data;
    }

    return (
        <Fragment>
            <MetaData title={"All Timesheets"} />
            <Sidebar />
            <div className="main-content py-8">
                <div className="container">
                    <h1 className="mb-5">All Timesheets</h1>
                    {loading ? (
                        <Loader />
                    ) : (
                        <MDBDataTable data={setUsers()} noBottomColumns={true} className="px-3" bordered striped hover responsive />
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default TimesheetList
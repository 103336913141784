import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearError, updatePassword } from "../../actions/userActions";
import { UPDATE_PASSWORD_RESET } from "../../constants/userConstants";
import MetaData from "../layouts/MetaData";

const UpdatePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { error, isUpdate, loading } = useSelector((state) => state.user);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearError());
    }

    if (isUpdate) {
      alert.success("Password updated successfully");
      navigate("/profile");
      dispatch({
        type: UPDATE_PASSWORD_RESET,
      });
    }
  }, [dispatch, alert, error, isUpdate, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.set("oldPassword", oldPassword);
    formData.set("password", password);

    dispatch(updatePassword(formData));
  };

  return (
    <Fragment>
      <MetaData title={"Update Password"} />
      <div className="py-8">
        <div className="container">
          <div className="row row-grid justify-content-center">
            <div className="col-md-5">
              <form onSubmit={submitHandler}>
                {/* Password */}
                <div className="actions-toolbar py-2 mb-4">
                  <h5>Change password</h5>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-control-label">Old password</label>
                      <input className="form-control" type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-control-label">New password</label>
                      <input className="form-control" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <button type="submit" className="btn btn-sm btn-block btn-primary" disabled={loading ? true : false}>
                    Update password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdatePassword;

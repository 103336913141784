import {
    ALL_TIMESHEET_REQUEST,
    ALL_TIMESHEET_SUCCESS,
    ALL_TIMESHEET_FAIL,
    CLEAR_ERROR,
    GET_SELECTED_TIMESHEET,
    UPDATE_SELECTED_TIMESHEET_WORKING_HOURS,
    UPDATE_TIMESHEET_REQUEST,
    UPDATE_TIMESHEET_SUCCESS,
    UPDATE_TIMESHEET_FAIL,
    MESSAGE_RESET,
    CREATE_TIMESHEET_REQUEST,
    CREATE_TIMESHEET_SUCCESS,
    CREATE_TIMESHEET_FAIL,
    USER_TIMESHEET_REQUEST,
    USER_TIMESHEET_SUCCESS,
    USER_TIMESHEET_FAIL,
    USER_TIMESHEET_RESET,
    GET_SELECTED_TIMESHEET_BY_USER_ID,
    UPDATE_SELECTED_TIMESHEET_WORKING_HOURS_BY_ID,
    UPDATE_ISSUBMITTED_REQUEST,
    UPDATE_ISSUBMITTED_SUCCESS,
    UPDATE_ISSUBMITTED_FAIL,
    ALL_TIMESHEET_BY_MONTH_REQUEST,
    ALL_TIMESHEET_BY_MONTH_SUCCESS,
    ALL_TIMESHEET_BY_MONTH_FAIL,
    GET_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST,
    GET_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS,
    GET_TIMESHEET_BY_MONTH_AND_YEAR_FAIL,
    CREATE_TIMESHEET_SUCCESS_NEW,
    UPDATE_TIMESHEET_SUCCESS_NEW,
    USER_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST,
    USER_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS,
    USER_TIMESHEET_BY_MONTH_AND_YEAR_FAIL,
    USER_TIMESHEET_BY_MONTH_AND_YEAR_RESET,
    UPDATE_ISSUBMITTED_SUCCESS_NEW,
    ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST,
    ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS,
    ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_FAIL,
} from '../constants/timesheetConstants'

import { checkForCurrentMonthAndCreateTimeSheet, getEmptyTimesheetWithMonthAndYear, getFilledTimesheetWithMonthAndYear } from '../utils/Data'

export const timesheetReducers = (state = { timesheets: [] }, action) => {
    switch (action.type) {
        case ALL_TIMESHEET_BY_MONTH_REQUEST:
        case GET_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST:
        case ALL_TIMESHEET_REQUEST:
        case ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST:
            return {
                loading: true,
                timesheets: []
            }

        case CREATE_TIMESHEET_REQUEST:
        case UPDATE_TIMESHEET_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case ALL_TIMESHEET_SUCCESS:
            let newTimesheet = checkForCurrentMonthAndCreateTimeSheet(action.payload.timesheets)
            return {
                loading: false,
                timesheets: newTimesheet,
                selectedMonth: newTimesheet[newTimesheet.length - 1].month,
                selectedTimesheet: newTimesheet.find((element) => {
                    return element.month === newTimesheet[newTimesheet.length - 1].month;
                })
            }

        case GET_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS:
            let newTimeSheet;
            if (action.payload.timesheet.length > 0) {
                newTimeSheet = getFilledTimesheetWithMonthAndYear(action.payload.month, action.payload.year, action.payload.timesheet[0])
            } else {
                newTimeSheet = getEmptyTimesheetWithMonthAndYear(action.payload.month, action.payload.year)
            }

            return {
                ...state,
                loading: false,
                selectedTimesheet: newTimeSheet,
            }

        case ALL_TIMESHEET_BY_MONTH_SUCCESS:
            return {
                ...state,
                loading: false,
                monthTimesheets: action.payload.timesheets,
            }

        case ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                monthAndYearTimesheets: action.payload.timesheets,
            }

        case UPDATE_TIMESHEET_SUCCESS_NEW:
        case CREATE_TIMESHEET_SUCCESS_NEW:
            let sendTimesheet = getFilledTimesheetWithMonthAndYear(action.payload.timesheet.month, action.payload.timesheet.year, action.payload.timesheet)
            return {
                ...state,
                loading: false,
                message: action.payload.message,
                selectedTimesheet: sendTimesheet
            }

        case UPDATE_TIMESHEET_SUCCESS:
        case CREATE_TIMESHEET_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload.message,
                timesheets: checkForCurrentMonthAndCreateTimeSheet(action.updatedTimesheet),
                selectedMonth: action.payload.timesheet.month,
                selectedTimesheet: action.payload.timesheet
            }

        case ALL_TIMESHEET_BY_MONTH_FAIL:
        case GET_TIMESHEET_BY_MONTH_AND_YEAR_FAIL:
        case ALL_TIMESHEET_FAIL:
        case ADMIN_TIMESHEET_BY_MONTH_AND_YEAR_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CREATE_TIMESHEET_FAIL:
        case UPDATE_TIMESHEET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        case MESSAGE_RESET:
            return {
                ...state,
                message: null
            }

        case GET_SELECTED_TIMESHEET:
            return {
                ...state,
                selectedMonth: action.month,
                selectedTimesheet: action.timesheet
            }

        case UPDATE_SELECTED_TIMESHEET_WORKING_HOURS:
            return {
                ...state,
                selectedTimesheet: action.timesheet
            }

        default:
            return state;
    }
}


export const userTimesheetReducers = (state = { timesheets: [] }, action) => {
    switch (action.type) {
        case USER_TIMESHEET_REQUEST:
        case USER_TIMESHEET_BY_MONTH_AND_YEAR_REQUEST:
            return {
                loading: true,
                timesheets: []
            }

        case UPDATE_ISSUBMITTED_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case UPDATE_ISSUBMITTED_SUCCESS_NEW:
            let sendTimesheet = getFilledTimesheetWithMonthAndYear(action.payload.timesheet.month, action.payload.timesheet.year, action.payload.timesheet)
            return {
                ...state,
                loading: false,
                message: action.payload.message,
                selectedTimesheet: sendTimesheet
            }

        case UPDATE_ISSUBMITTED_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload.message,
                timesheets: action.updatedTimesheet,
                selectedMonth: action.payload.timesheet.month,
                selectedTimesheet: action.payload.timesheet
            }

        case USER_TIMESHEET_SUCCESS:
            return {
                loading: false,
                timesheets: action.payload.timesheets,
                selectedUser: action.userId,
                selectedMonth: action.payload.timesheets.length >= 1 ? action.payload.timesheets[action.payload.timesheets.length - 1].month : '',
                selectedTimesheet: action.payload.timesheets.length >= 1 ? action.payload.timesheets.find((element) => {
                    return element.month === action.payload.timesheets[action.payload.timesheets.length - 1].month;
                }) : ''
            }

        case USER_TIMESHEET_BY_MONTH_AND_YEAR_SUCCESS:
            let newTimeSheet;
            if (action.payload.timesheet.length > 0) {
                newTimeSheet = getFilledTimesheetWithMonthAndYear(action.payload.month, action.payload.year, action.payload.timesheet[0])
            }

            return {
                ...state,
                loading: false,
                selectedTimesheet: newTimeSheet,
            }

        case USER_TIMESHEET_FAIL:
        case USER_TIMESHEET_BY_MONTH_AND_YEAR_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case UPDATE_ISSUBMITTED_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case USER_TIMESHEET_RESET:
        case USER_TIMESHEET_BY_MONTH_AND_YEAR_RESET:
            return {
                ...state,
                timesheets: null,
                selectedMonth: null,
                selectedTimesheet: null,
                selectedUser: null
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }

        case GET_SELECTED_TIMESHEET_BY_USER_ID:
            return {
                ...state,
                selectedMonth: action.month,
                selectedTimesheet: action.timesheet
            }

        case UPDATE_SELECTED_TIMESHEET_WORKING_HOURS_BY_ID:
            return {
                ...state,
                selectedTimesheet: action.timesheet
            }


        default:
            return state;
    }
}

import { MDBDataTable } from "mdbreact";
import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { allUsers, clearError, deleteUser, reactivateUser } from "../../actions/userActions";
import { DELETE_USER_RESET, REACTIVATE_USER_RESET } from "../../constants/userConstants";

import Sidebar from "../admin/Sidebar";
import Loader from "../layouts/Loader";
import MetaData from "../layouts/MetaData";

const DisabledUsers = () => {

    const alert = useAlert();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, error, deactiveUsers } = useSelector((state) => state.allUsers);

    const { loading: deleteLoading, error: deleteError, isDeleted, isReactivated, loading: reactivateLoading, error: reactivateError } = useSelector((state) => state.user);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(allUsers());

        if (error) {
            alert.error(error);
            dispatch(clearError());
        }

        if (deleteError) {
            alert.error(deleteError);
            dispatch(clearError());
        }

        if (reactivateError) {
            alert.error(reactivateError);
            dispatch(clearError());
        }

        if (isDeleted) {
            alert.success("User deleted successfully");
            navigate("/admin/users");
            dispatch({ type: DELETE_USER_RESET });
        }

        if (isReactivated) {
            alert.success("User activated successfully");
            navigate("/admin/users");
            dispatch({ type: REACTIVATE_USER_RESET });
        }

    }, [dispatch, alert, error, deleteError, isDeleted, navigate, deleteLoading, isReactivated, reactivateError, reactivateLoading]);

    const setUsers = () => {
        const data = {
            columns: [
                {
                    label: "User ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                },
                {
                    label: "Email",
                    field: "email",
                    sort: "asc",
                },
                {
                    label: "Role",
                    field: "role",
                    sort: "asc",
                },
                {
                    label: "Actions",
                    field: "actions",
                },
            ],
            rows: [],
        };

        deactiveUsers.forEach((user) => {
            data.rows.push({
                id: user._id,
                name: user.name,
                email: user.email,
                role: user.role,

                actions: (
                    <Fragment>
                        <button className="btn btn-primary py-1 px-2 ml-2" onClick={() => activateUserHandler(user._id)}>
                            <i className="fa fa-user"></i>
                        </button>
                        <button className="btn btn-danger py-1 px-2 ml-2" onClick={() => deleteUserHandler(user._id)}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </Fragment>
                ),
            });
        });

        return data;
    };

    const deleteUserHandler = (id) => {
        if (id === user._id) {
            alert.error("Logged in user cannot be deleted");
        } else {
            dispatch(deleteUser(id));
        }
    };

    const activateUserHandler = (id) => {
        if (id === user._id) {
            alert.error("Logged in user cannot be activated");
        } else {
            dispatch(reactivateUser(id));
        }
    };

    return (
        <Fragment>
            <MetaData title={"All Users"} />
            <Sidebar />
            <div className="main-content py-8">
                <div className="container">
                    <h1 className="mb-5">Deactivate Users</h1>
                    {loading || deleteLoading || reactivateLoading ? (
                        <Loader />
                    ) : (
                        <MDBDataTable data={setUsers()} noBottomColumns={true} className="px-3" bordered striped hover responsive />
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default DisabledUsers
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL'
export const REGISTER_USER_RESET = 'REGISTER_USER_RESET'

export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST'
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const LOAD_USER_FAIL = 'LOAD_USER_FAIL'

export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST'
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL'
export const FORGET_PASSWORD_RESET = 'FORGET_PASSWORD_RESET'

export const NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST'
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS'
export const NEW_PASSWORD_FAIL = 'NEW_PASSWORD_FAIL'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_RESET = 'UPDATE_PASSWORD_RESET'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'
export const LOGOUT_RESET = 'LOGOUT_RESET'

export const CLEAR_ERROR = 'CLEAR_ERROR'

export const ADMIN_USERS_REQUEST = 'ADMIN_USERS_REQUEST'
export const ADMIN_USERS_SUCCESS = 'ADMIN_USERS_SUCCESS'
export const ADMIN_USERS_FAIL = 'ADMIN_USERS_FAIL'


export const NEW_USER_REQUEST = 'NEW_USER_REQUEST'
export const NEW_USER_SUCCESS = 'NEW_USER_SUCCESS'
export const NEW_USER_RESET = 'NEW_USER_RESET'
export const NEW_USER_FAIL = 'NEW_USER_FAIL'

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_RESET = 'DELETE_USER_RESET'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_RESET = 'UPDATE_USER_RESET'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const DEACTIVATE_USER_REQUEST = 'DEACTIVATE_USER_REQUEST'
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS'
export const DEACTIVATE_USER_RESET = 'DEACTIVATE_USER_RESET'
export const DEACTIVATE_USER_FAIL = 'DEACTIVATE_USER_FAIL'

export const REACTIVATE_USER_REQUEST = 'REACTIVATE_USER_REQUEST'
export const REACTIVATE_USER_SUCCESS = 'REACTIVATE_USER_SUCCESS'
export const REACTIVATE_USER_RESET = 'REACTIVATE_USER_RESET'
export const REACTIVATE_USER_FAIL = 'REACTIVATE_USER_FAIL'
import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearError, newUser } from "../../actions/userActions";
import { NEW_USER_RESET } from "../../constants/userConstants";
import Sidebar from "../admin/Sidebar";
import Loader from "../layouts/Loader";
import MetaData from "../layouts/MetaData";

const Register = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    role: "user",
  });

  const { name, email, password, role } = user;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const { success, loading, error } = useSelector((state) => state.newUser);

  useEffect(() => {
    if (success) {
      navigate("/admin/users");
      alert.success("User created successfully");
      dispatch({ type: NEW_USER_RESET });
    }

    if (error) {
      alert.error(error);
      dispatch(clearError());
    }
  }, [dispatch, alert, success, error, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.set("name", name);
    formData.set("email", email);
    formData.set("password", password);
    formData.set("role", role);

    dispatch(newUser(formData));
  };

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={"Register"} />
          <Sidebar />
          <div className="main-content py-8">
            <div className="container">
              <h1 className="mb-5">Register User</h1>
              <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                  <form className="shadow-lg px-3 px-md-8 py-5" onSubmit={submitHandler}>
                    <div className="form-group">
                      <label className="form-control-label">Name</label>
                      <div className="input-group input-group-merge">
                        <input
                          type="text"
                          className="form-control"
                          id="input-name"
                          placeholder="John Doe"
                          required
                          name="name"
                          value={name}
                          onChange={onChange}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-control-label">Email address</label>
                      <div className="input-group input-group-merge">
                        <input
                          type="email"
                          className="form-control"
                          id="input-email"
                          placeholder="name@example.com"
                          required
                          name="email"
                          value={email}
                          onChange={onChange}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-control-label">Password</label>
                      <div className="input-group input-group-merge">
                        <input
                          type="password"
                          className="form-control"
                          id="input-password"
                          placeholder="name@example.com"
                          required
                          name="password"
                          value={password}
                          onChange={onChange}
                        />
                      </div>
                    </div>

                    <div>
                      <input type="radio" value="user" name="role" defaultChecked onChange={onChange} /> User
                      <input className="ml-3" type="radio" value="admin" name="role" onChange={onChange} /> Admin
                    </div>

                    <div className="mt-5 text-center">
                      <button type="submit" className="btn btn-primary" disabled={loading ? true : false}>
                        Register
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
};

export default Register;

import dateFormat from "dateformat";
import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { ToggleSwitch } from "react-dragswitch";
import "react-dragswitch/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import {
    getTimesheetByUserWithMonthAndYear,
    updateIsSubmittedTimesheetsNew,
    updateSelectedTimesheetHoursByUserId,
} from "../../actions/timesheetActions";
import { getMonthsList, getCurrentMonth, getCurrentYear, calculateTotalHoursByMonth, timesheetHours, checkDateBelongToCurrentMonth } from "../../utils/Data";
import Sidebar from "./Sidebar";
import Loader from "../layouts/Loader";
import { clearError } from "../../actions/userActions";


const TimesheetsByUser = () => {

    const params = useParams();
    const alert = useAlert();
    const dispatch = useDispatch();
    const {
        loading,
        error: timesheetError,
        selectedTimesheet,
    } = useSelector((state) => state.userTimesheets);
    const userId = params.id;

    const [monthList, setMonthList] = useState(getMonthsList())

    
    useEffect(() => {
        dispatch(getTimesheetByUserWithMonthAndYear(userId, getCurrentMonth(), getCurrentYear()));
    }, [dispatch]);

    useEffect(() => {

        if (timesheetError) {
            alert.error(timesheetError);
            dispatch(clearError());
        }
    }, [dispatch, alert, timesheetError]);


    const monthSelectionHandler = (monthValue, index) => {
        const monthStr = [...monthList];
        const updatedArray = monthStr.map(month => month.value === monthValue.value ? { ...month, isSelected: true } : { ...month, isSelected: false })
        setMonthList(updatedArray)

        dispatch(getTimesheetByUserWithMonthAndYear(userId, monthValue.value, monthValue.year));
    };

    const findTimesheetObjectByWorkingHours = (workingHour) => {
        return timesheetHours.find((element) => {
            return element.value === workingHour;
        });
    };

    const selectHandler = (e, day, index) => {
        let array = selectedTimesheet.timesheet;
        array[index] = { ...array[index], workingHours: e.value };

        dispatch(updateSelectedTimesheetHoursByUserId(selectedTimesheet));
    };

    const revokeHandler = (value) => {
        if (selectedTimesheet !== undefined && selectedTimesheet !== null) {
            let updatedTimesheet = { ...selectedTimesheet, isSubmitted: value };
            dispatch(updateIsSubmittedTimesheetsNew(updatedTimesheet));
        }
    };

    const customStyles = {
        control: (base, state) => ({
            ...base,
            fontSize: "10px",
            minHeight: "10px",
            color: 'black',
            border: "1px solid #6e00ff",
            "&:hover": {
                border: state.isFocused ? "1px solid #6e00ff" : "1px solid #6e00ff",
            },
        }),
        option: (base, state) => ({
            ...base,
            fontSize: "10px",
            color: 'black',
            minHeight: "10px",
        }),
    };

    const customStylesDisabled = {
        control: (base, state) => ({
            ...base,
            fontSize: "10px",
            minHeight: "10px",
            border: "1px solid #a6a6a6",
            "&:hover": {
                border: state.isFocused ? "1px solid #a6a6a6" : "1px solid #a6a6a6",
            },
        }),
        option: (base, state) => ({
            ...base,
            fontSize: "10px",
            minHeight: "10px",
        }),
    };

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Sidebar />
                    <div className="main-content py-8">
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-6 col-md-2">
                                    <div className="card position bg-white">
                                        <div className="card-header py-3 bg-search">
                                            <span className="h6">Months</span>
                                        </div>
                                        <div className="list-group list-group-sm list-group-flush">
                                            {monthList.map((month, i) => {
                                                return (
                                                    <Link
                                                        key={i}
                                                        onClick={() => monthSelectionHandler(month)}
                                                        className={month.isDisabled ? "list-group-disabled" :
                                                            "list-group-item list-group-item-action cursor-pointer" +
                                                            (month.isSelected ? " bg-primary text-white font-weight-bold" : "")
                                                        }
                                                    >
                                                        <div>
                                                            <span>{month.label}</span>
                                                        </div>
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-8">
                                    <h1 className="mb-2">{selectedTimesheet?.user?.name} Timesheet</h1>
                                    {selectedTimesheet && selectedTimesheet.timesheet.length >= 1 && (
                                        <label>
                                            <span>Revoke Timesheet: </span>
                                            <ToggleSwitch checked={selectedTimesheet.isSubmitted} onChange={revokeHandler} />
                                        </label>
                                    )}
                                    <div className="card pt-3 pt-md-4">
                                        <div className="row justify-content-center">
                                            {!selectedTimesheet ? (
                                                <div>
                                                    <h3 className="h5 text-center mb-4">No Timesheet Found</h3>
                                                </div>
                                            ) : (
                                                selectedTimesheet.timesheet.map((object, index) => {
                                                    return (
                                                        <div className="card col-custom-7 mx-1" key={index}>
                                                            <div className={(object.workingHours === "H" ? "card-body-timesheet-disabled py-3 w-100" : object.workingHours === "NH" ? "card-body-timesheet-national-holiday py-3 w-100" : object.workingHours === "V" || object.workingHours === "PL" || object.workingHours === "PCL" || object.workingHours === "PH" || object.workingHours === "S"  ? "card-body-timesheet-vacation py-3 w-100" : checkDateBelongToCurrentMonth(object.date, selectedTimesheet && selectedTimesheet.month) ? "card-body-timesheet py-3 w-100" : "card-body-timesheet-disabled py-3 w-100")}>
                                                                <p className="lh-100 mb-1">
                                                                    <small>{dateFormat(object.date, "dd/mm/yyyy ddd")}</small>
                                                                </p>
                                                                <Select
                                                                    components={{
                                                                        DropdownIndicator: () => null,
                                                                        IndicatorSeparator: () => null,
                                                                    }}
                                                                    styles={object.workingHours === "H" ? customStylesDisabled : checkDateBelongToCurrentMonth(object.date, selectedTimesheet && selectedTimesheet.month) ? customStyles : customStylesDisabled}
                                                                    name={index}
                                                                    options={timesheetHours}
                                                                    value={findTimesheetObjectByWorkingHours(object.workingHours)}
                                                                    onChange={(e) => selectHandler(e, object.day, index)}
                                                                    isDisabled={object.workingHours === "H" ? true : checkDateBelongToCurrentMonth(object.date, selectedTimesheet && selectedTimesheet.month) ? false : true}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            )}
                                        </div>
                                    </div>

                                    {selectedTimesheet && selectedTimesheet.timesheet.length >= 1 && (
                                        <div className="text-center">
                                            <p className="text-center">
                                                <small>
                                                    *&nbsp;&nbsp;<b>8</b>: For full working day &nbsp;&nbsp;
                                                    <b>4</b>: For half working day &nbsp;&nbsp;<b>S</b>: For sick leave &nbsp;&nbsp;
                                                    <b>V</b>: For vacation/holiday &nbsp;&nbsp;<b>NH</b>: For National holidays &nbsp;&nbsp;
                                                    <b>PL</b>: For parental leave &nbsp;&nbsp;<b>PCL</b>: For paid compensation leave&nbsp;&nbsp;
                                                    <b>PH</b>: For paid holiday. &nbsp;&nbsp;
                                                </small>
                                            </p>
                                            <div className="row justify-content-center">
                                                <h4>
                                                    Total Days:{" "} {calculateTotalHoursByMonth(selectedTimesheet &&
                                                        selectedTimesheet.timesheet, selectedTimesheet &&
                                                    selectedTimesheet.month) / 8}
                                                </h4>
                                                <h4 className="ml-5">
                                                    Total Hours:{" "} {calculateTotalHoursByMonth(selectedTimesheet &&
                                                        selectedTimesheet.timesheet, selectedTimesheet &&
                                                    selectedTimesheet.month)}
                                                </h4>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Fragment>
    );
}

export default TimesheetsByUser
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import Loader from '../layouts/Loader' 

const ProtectedRoute = ({ children, isAdmin }) => {

    const { isAuthenticated, loading, user } = useSelector(state => state.auth)
    

    if (loading) return <Loader/>

    if (!loading && isAuthenticated === false) {
        return <Navigate to="/" />
    } else if (isAdmin === true && user.role !== 'admin') {
        return <Navigate to="/home" />
    } else {
        return children
    }
}

export default ProtectedRoute
import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';

import {productReducers} from './reducers/productReducers'
import {timesheetReducers, userTimesheetReducers} from './reducers/timesheetReducers'
import {authReducers, userReducers, forgotPasswordReducers, allUsersReducers, newUserReducers, userDetailsReducer} from './reducers/userReducers'

const reducers = combineReducers({
    products: productReducers,
    auth: authReducers,
    user: userReducers,
    forgotPassword: forgotPasswordReducers,
    timesheets: timesheetReducers,
    allUsers: allUsersReducers,
    newUser: newUserReducers,
    userDetails: userDetailsReducer,
    userTimesheets: userTimesheetReducers
})

let initialState = {}

const middleWare = [thunk]

const store = createStore(reducers, initialState, composeWithDevTools(applyMiddleware(...middleWare)))

export default store;
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assets/docs.css";
import "./assets/theme.css";
import Home from "./components/Home";
import Footer from "./components/layouts/Footer";
import Header from "./components/layouts/Header";
import ProtectedRoute from "./components/route/ProtectedRoute";
import ForgotPassword from "./components/user/ForgotPassword";
import Login from "./components/user/Login";
import Profile from "./components/user/Profile";
import Register from "./components/user/Register";
import DisabledUsers from "./components/user/DisabledUsers";
import ResetPassword from "./components/user/ResetPassword";
import UpdatePassword from "./components/user/UpdatePassword";

import Dashboard from "./components/admin/Dashboard";
import Timesheets from "./components/admin/Timesheets";
import UpdateUser from "./components/admin/UpdateUser";
import UsersList from "./components/admin/UsersList";

import { useEffect } from "react";
import { loadUser } from "./actions/userActions";
import store from "./Store";
import TimesheetList from "./components/admin/TimesheetList";
import TimesheetsByUser from "./components/admin/TimesheetsByUser";
import HomeNew from "./HomeNew";

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Router>
      <div className="App">
        <Header />
        <div className="position-relative min-vh-100">
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route
              exact
              path="/home"
              element={
                <ProtectedRoute>
                  <HomeNew />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/password/update"
              element={
                <ProtectedRoute>
                  <UpdatePassword />
                </ProtectedRoute>
              }
            />
            <Route exact path="/password/forgot" element={<ForgotPassword />} />
            <Route exact path="/password/reset/:token" element={<ResetPassword />} />
          </Routes>

          <Routes>
            <Route
              exact
              path="/dashboard"
              element={
                <ProtectedRoute isAdmin={true}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/timesheets"
              element={
                <ProtectedRoute isAdmin={true}>
                  <Timesheets />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/users"
              element={
                <ProtectedRoute isAdmin={true}>
                  <UsersList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/timesheetList"
              element={
                <ProtectedRoute isAdmin={true}>
                  <TimesheetList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/register"
              element={
                <ProtectedRoute isAdmin={true}>
                  <Register />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/users/disabled"
              element={
                <ProtectedRoute isAdmin={true}>
                  <DisabledUsers />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/user/:id"
              element={
                <ProtectedRoute isAdmin={true}>
                  <UpdateUser />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/timesheets/:id"
              element={
                <ProtectedRoute isAdmin={true}>
                  <TimesheetsByUser />
                </ProtectedRoute>
              }
            />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;

import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <nav
      className="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white mt-auto overflow-hidden border-top-dashed sidebar-height"
      id="sidenav-main"
    >
      <div className="px-3 scrollbar-inner">
        <div className="collapse navbar-collapse" id="sidenav-collapse-main">
          <ul className="navbar-nav navbar-nav-docs pt-2">
            <li className="nav-item">
              <Link className="nav-link" to="/dashboard" role="button" aria-expanded="false" aria-controls="navbar-getting-started">
                Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/admin/timesheetList" role="button" aria-expanded="false" aria-controls="navbar-getting-started">
                Timesheets
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#navbar-users" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="navbar-users">
                Users
              </a>
              <div className="collapse" id="navbar-users">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <Link to="/admin/users" className="nav-link">
                      All
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/register" className="nav-link">
                      Create
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/admin/users/disabled" className="nav-link">
                      Disabled
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
